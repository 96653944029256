import i18next from "i18next";
import React from "react";
import { withTranslation } from 'react-i18next';
import '../../style/homePageStyle.css';
import AboutUs from "../aboutUsPage/aboutUs";
import Services from "../servicesPage/services";

class Home extends React.Component<{}, {pack: number}> {
    constructor(props: any) {
      super(props);
    }
  
    render() {
      return (
        <>
          <div className="page">
            <div className="parallax">
              <p className="homepageImageText align-items-center">{i18next.t('HOMEPAGE.IMAGE_LISBON_TEXT').toString()}</p>
            </div>
            <AboutUs />
            <div className="parallax2"></div>
            <Services />
            <div className="parallax2"></div>
          </div>
        </>
      );
    }
}

export default withTranslation()(Home);