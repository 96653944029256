import React, { ReactNode } from 'react';
import { LoaderProvider } from './loaderContext';

interface PageProps {
  children: ReactNode;
}

const AppProvider = ({ children }: PageProps) => {
  return (
    <LoaderProvider>
        {children}
    </LoaderProvider>
  );
};

export default AppProvider;