import i18next from "i18next";
import React from "react";
import { withTranslation } from 'react-i18next';
import { useForm, ValidationError } from '@formspree/react';

function Contacts() {
  const [state, handleSubmit] = useForm("xzbqagdr");
  
  return (
    <>
    <div className="page">
      <div className="parallax2"></div>
      <div className="container-fluid pt-5 pb-5">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-12 col-md-8">
            <h4>{i18next.t('CONTACTUS.TITLE').toString()}</h4>
            <form className="contact-form" onSubmit={handleSubmit}>
              <div className="form-group mb-3">
              <input id="name" name="name" required type="text" className="form-control btnFormFields" placeholder={i18next.t('CONTACTUS.NAME').toString()} />
              <ValidationError 
                prefix="Name" 
                field="name"
                errors={state.errors}
              />
              </div>
              <div className="form-group mb-3">
              <input required type="text" name="email" id="email" className="form-control btnFormFields" placeholder={i18next.t('CONTACTUS.EMAIL').toString()} />
              <ValidationError 
                prefix="Email" 
                field="email"
                errors={state.errors}
              />
              </div>
              <div className="form-group mb-3">
              <input required type="text" name="phoneNumber" id="phoneNumber" className="form-control btnFormFields" placeholder={i18next.t('CONTACTUS.PHONE_NUMBER').toString()} />
              <ValidationError 
                prefix="PhoneNumber" 
                field="phoneNumber"
                errors={state.errors}
              />
              </div>
              <div className="form-group mb-3">
              <input required name="subject" id="subject" type="text" className="form-control btnFormFields" placeholder={i18next.t('CONTACTUS.SUBJECT').toString()} />
              <ValidationError 
                prefix="Subject" 
                field="subject"
                errors={state.errors}
              />
              </div>
              <div className="form-group mb-3">
              <textarea required name="message" id="message" className="form-control btnFormFields" placeholder={i18next.t('CONTACTUS.MESSAGE').toString()}></textarea>
              <ValidationError 
                prefix="Message" 
                field="message"
                errors={state.errors}
              />
              </div>
              {state.succeeded && <div className="alert alert-success alert-dismissible fade show" role="alert">
                {i18next.t('SUCCESS_MESSAGES.SEND_EMAIL_SUCCESS_MESSAGE').toString()}
              </div>}
              {!!state.errors && state.errors.length > 0 && <div className="alert alert-danger alert-dismissible fade show" role="alert">
                {i18next.t('ERROR_MESSAGES.SEND_EMAIL_ERROR_MESSAGE').toString()}
              </div>}
              <div className="form-group">
                <button type="submit" disabled={state.submitting} className="btn btnFormContact">
                {i18next.t('GENERAL.SEND').toString()}
                </button>
              </div>
            </form>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
      <div className="parallax2"></div>
    </div>
    </>
  );
}

export default withTranslation()(Contacts);