import React from 'react';
import App from './rootComponent/App';
import AppProvider from './contexts/appProvider';
import { createRoot } from "react-dom/client";
import Navbar from './components/Navbar';
import './i18n';
import './globalCSS/app.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Footer from './components/Footer';
import TopButton from './components/TopButton';
import { BrowserRouter } from "react-router-dom";

const { PUBLIC_URL } = process.env;

const root = createRoot(document.getElementById("root")!);

root.render(
  <React.StrictMode>
    <AppProvider>
      <BrowserRouter basename={PUBLIC_URL}>
        <Navbar />
        <App />
        <TopButton />
        <Footer />
      </BrowserRouter>
    </AppProvider>
  </React.StrictMode>
);