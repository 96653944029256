import i18next from "i18next";
import React from "react";
import { withTranslation } from 'react-i18next';
import '../style/navbarStyle.css';
const { PUBLIC_URL } = process.env;

class Navbar extends React.Component {
    constructor(props: any) {
      super(props);
    }
  
    render() {
        const { pathname } = location;
        return (
            <nav className="navbar navbar-expand-md navbar-light bg-light">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/">
                        <img src={require("../images/LOGO-A4S.png")} width="250" height="100" alt="ask4service" />
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
                        <div className="navbar-nav">
                            <a className={pathname !== "/" ? "nav-item nav-link" : "nav-item nav-link active"} href="/">{i18next.t('ROUTES.HOMEPAGE').toString()}</a>
                            <a className="nav-item nav-link" href="/#AboutUs">{i18next.t('ROUTES.ABOUTUS').toString()}</a>
                            <a className="nav-item nav-link" href="/#Services">{i18next.t('ROUTES.SERVICES').toString()}</a>
                            <a className={pathname !== "/Contacts" ? "nav-item nav-link" : "nav-item nav-link active"} href={PUBLIC_URL+"/Contacts"}>{i18next.t('ROUTES.CONTACTS').toString()}</a>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}

export default withTranslation()(Navbar);