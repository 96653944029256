import { Routes, Route } from 'react-router-dom';
import _ from 'lodash';
import Home from '../pages/homePage/home';
import Contacts from '../pages/contactsPage/contacts';
import React from 'react';

const App = () => {
  return (
    <>
      {/*<Loader show={showLoader} />*/}
        <Routes>
          <Route path="/Contacts" element={<Contacts />} />
          <Route exact path="/" element={<Home />} />
        </Routes>
    </>
  );
};

export default App;
