import i18next from "i18next";
import React from "react";
import { withTranslation } from 'react-i18next';
import '../style/topButtonStyle.css';

class TopButton extends React.Component {
    constructor(props: any) {
      super(props);
    }    

    componentDidMount(): void {
        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {scrollFunction()};
    }

    topFunction() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
  
    render() {
        return (
            <button onClick={() => {this.topFunction()!}} id="myBtn" title="Go to top"><i className="bi bi-arrow-up"></i></button>
        );
    }
}

export default withTranslation()(TopButton);

function scrollFunction() {
    let mybutton = document.getElementById("myBtn");
    if(!!mybutton){
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            mybutton.style.display = "block";
        } else {
            mybutton.style.display = "none";
        }
    }
}
