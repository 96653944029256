import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translations: {
          "GENERAL": {
            "SEND": "Send",
          },
          "SUCCESS_MESSAGES": {
            "SEND_EMAIL_SUCCESS_MESSAGE": "Your email has been sent sucessfully."
          },
          "ERROR_MESSAGES": {
            "SEND_EMAIL_ERROR_MESSAGE": "Your email could not be sent. Please try again later."
          },
          "ROUTES": {
            "HOMEPAGE": "Home",
            "CONTACTS": "Contacts",
            "ABOUTUS": "About Us",
            "SERVICES": "Services"
          },
          "HOMEPAGE": {
            "IMAGE_LISBON_TEXT": "We simplify your life."
          },
          "ABOUTUS": {
            "FIRST_TEXT": "we are everything that your property needs.",
            "SECOND_TEXT": "Why Property Owners Choose Us:",
            "FIRST_OFFER": "Costumised packages",
            "SECOND_OFFER": "Dedicated point of contact",
            "THIRD_OFFER": "Keeping you up-to-date"
          },
          "SERVICES": {
            "SERVICE_ONE": "Property Management",
            "SERVICE_TWO": "Maintenance & Remodeling",
            "SERVICE_THREE": "Decoration",
            "PACKS_TEXT": "Our clients can choose between three packages Basic, Medium and Top. \nCustomised solutions can be offered as well upon consultation.",
            "PACK_BASIC": "BASIC",
            "PACK_MEDIUM": "MEDIUM",
            "PACK_TOP": "TOP",
            "PACK_COSTUMISED": "COSTUMISED",
            "PACK_BASIC_TEXT": "Opening and closing the shutters and windows to air the property, flushing toilets and running the water to keep the pipes clean, checking all utility connections and if there are any issues that require maintenance, mail collection and verification and send metre reading (Water, Electricity, Gas). \n Property check is done once every month.",
            "PACK_MEDIUM_TEXT": "Opening and closing the shutters and windows to air the property, flushing toilets and running the water to keep the pipes clean, checking all utility connections and if there are any issues that require maintenance, mail collection and verification and send metre reading (Water, Electricity, Gas). \n Property checks are done twice every month.",
            "PACK_TOP_TEXT": "Opening and closing the shutters and windows to air the property, flushing toilets and running the water to keep the pipes clean, checking all utility connections and if there are any issues that require maintenance, mail collection and verification and send metre reading (Water, Electricity, Gas). \n Property checks are done once a week.",
            "PACK_COSTUMISED_TEXT": "On this package we check with the customer which property management services are required, as well as their frequency, and agree a price.",
            "TYPE_OF_SERVICES": "Maintenance & Remodeling \n Plumbing • Electrical • Painting \n Pool • Garden • Construction Works",
            "TEXT": "If any issues arise with your property, we will inform you and, if necessary, organize a quote and oversee any work required to fix problems. We handle small maintenance issues such as changing lightbulbs or replacing small items due to wear and tear, and we also have a partnership with many companies to ensure any repair its need or solve any problem.",
            "DECORATION": "Decoration",
            "DECORATION_1": "Architecture projects",
            "DECORATION_2": "Interior",
            "DECORATION_3": "Exterior",
            "OTHERS_SERVICES": "Others Services",
            "OTHERS_SERVICES_1": "Laundry Service",
            "OTHERS_SERVICES_2": "Shopping (Food, Drinks, etc)",
            "OTHERS_SERVICES_3": "Receive Orders",
            "OTHERS_SERVICES_4": "Driver Service",
            "CLEANING_SERVICE_TITLE": "Cleaning Services",
            "CLEANING_SERVICE_TEXT": "We have a cleaning team that ensure when you arrive in Portugal your house is always clean and fresh. You can choose the frequency of cleaning."
          },
          "CONTACTUS": {
            "TITLE": "Contact Us",
            "NAME": "Your Name",
            "EMAIL": "Your Email",
            "SUBJECT": "Subject",
            "MESSAGE": "Message",
            "PHONE_NUMBER": "Your Phone Number"
          }
        }
      }
    },
    fallbackLng: "en",
    debug: false,
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
    // keySeparator: false, // we use content as keys
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;