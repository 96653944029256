import { faSquareWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18next from "i18next";
import React from "react";
import { withTranslation } from 'react-i18next';
import '../style/navbarStyle.css';

class Footer extends React.Component {
    constructor(props: any) {
      super(props);
    }
  
    render() {
        return (
            <footer className="footer mt-auto py-3 bg-light">
            <div className="container text-center">
                <div className="mb-3">
                    <a className="text-muted emailLink" href="mailto: geral@ask4service.pt">geral@ask4service.pt</a>
                </div>
                <div className="mb-3">
                    <a className="whatsAppLink" aria-label="Chat on WhatsApp" href="https://wa.me/351910468900"> <FontAwesomeIcon className="whatsAppLinkIcon" icon={faSquareWhatsapp} size="lg" /> Chat on WhatsApp </a>
                </div>
                <p className="text-muted">geral@ask4service.pt</p>
                <p className="text-muted">+351 910468900</p>
                <p className="text-muted">© 2023 Ask4Service.  All rights Reserved.</p>
            </div>
            </footer>
        );
    }
}

export default withTranslation()(Footer);