import i18next from "i18next";
import React from "react";
import { withTranslation } from 'react-i18next';

class AboutUs extends React.Component {
    constructor(props: any) {
      super(props);
    }
  
    render() {
      return (
        <>
            <div id="AboutUs" className="container-fluid pt-5 pb-5">
                <div className="row">
                    <div className="col-12">
                        <h4 className="text-center mt-3 textConditions">At <b>ASK<sup className="supText">4</sup>SERVICE</b> {i18next.t('ABOUTUS.FIRST_TEXT').toString()} </h4>
                    </div>
                    <div className="col-12">
                        <h5 className="text-center mt-2 textConditions">{i18next.t('ABOUTUS.SECOND_TEXT').toString()}</h5>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="text-center">
                            <i className="bi bi-tools icon"></i>
                            <p className="textOffersConditions">{i18next.t('ABOUTUS.FIRST_OFFER').toString()}</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="text-center">
                            <i className="bi bi-wechat icon"></i>
                            <p className="textOffersConditions">{i18next.t('ABOUTUS.SECOND_OFFER').toString()}</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="text-center">
                            <i className="bi bi-send-check-fill icon"></i>
                            <p className="textOffersConditions">{i18next.t('ABOUTUS.THIRD_OFFER').toString()}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
      );
    }
}

export default withTranslation()(AboutUs);