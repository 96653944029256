import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18next from "i18next";
import React from "react";
import { withTranslation } from 'react-i18next';
import { faBasketShopping, faCar, faCompassDrafting, faCouch, faDolly, faRug } from '@fortawesome/free-solid-svg-icons';
import { faPagelines } from '@fortawesome/free-brands-svg-icons';

class Services extends React.Component<{}, {pack: number}> {
    constructor(props: any) {
      super(props);
      this.state = {
        pack: 1
      };
    }
  
    render() {
      var {pack} = this.state;
      return (
        <>
          <div id="Services" className="container-fluid pt-5 pb-5">
            <div className="row">
              <div className="col-md-1 col-lg-2">
              </div>
              <div className="col-12 col-md-10 col-lg-8 mt-4 mb-4">
                <h4 className="text-bold">{i18next.t('SERVICES.SERVICE_ONE').toString()}</h4>
                <p className="mt-4 mb-5 servicesText">{i18next.t('SERVICES.PACKS_TEXT').toString()}</p>
                <div className="packsLinks mb-4 row">
                  <div className="col-6 col-md-3">
                    <p className={pack === 1 ? "packsLinkActive" : "packsLink"} onClick={() => this.setState({...this.state, pack: 1})}>
                      {i18next.t('SERVICES.PACK_BASIC').toString()}
                    </p>
                  </div>
                  <div className="col-6 col-md-3">
                    <p className={pack === 2 ? "packsLinkActive" : "packsLink"} onClick={() => this.setState({...this.state, pack: 2})}>
                      {i18next.t('SERVICES.PACK_MEDIUM').toString()}
                    </p>
                  </div>
                  <div className="col-6 col-md-3">
                    <p className={pack === 3 ? "packsLinkActive" : "packsLink"} onClick={() => this.setState({...this.state, pack: 3})}>
                      {i18next.t('SERVICES.PACK_TOP').toString()}
                    </p>
                  </div>
                  <div className="col-6 col-md-3">
                    <p className={pack === 4 ? "packsLinkActive" : "packsLink"} onClick={() => this.setState({...this.state, pack: 4})}>
                      {i18next.t('SERVICES.PACK_COSTUMISED').toString()}
                    </p>
                  </div>
                </div>
                <div className="mb-4 row">
                  {pack === 1 && <div className="card card-body cardPacks servicesText">
                    {i18next.t('SERVICES.PACK_BASIC_TEXT').toString()}
                  </div>}
                  {pack === 2 && <div className="card card-body cardPacks servicesText">
                    {i18next.t('SERVICES.PACK_MEDIUM_TEXT').toString()}
                  </div>}
                  {pack === 3 && <div className="card card-body cardPacks servicesText">
                    {i18next.t('SERVICES.PACK_TOP_TEXT').toString()}
                  </div>}
                  {pack === 4 && <div className="card card-body cardPacks servicesText">
                    {i18next.t('SERVICES.PACK_COSTUMISED_TEXT').toString()}
                  </div>}
                </div>
                  <h4 className="text-bold pt-5 mt-5">{i18next.t('SERVICES.CLEANING_SERVICE_TITLE').toString()}</h4>
                  <hr className="traceStart" />
                  <p className="servicesText">
                    {i18next.t('SERVICES.CLEANING_SERVICE_TEXT').toString()}
                  </p>
                  <hr className="traceEnd" />
                  <h4 className="pt-5 mt-5 mb-4 pb-4 text-bold">
                  {i18next.t('SERVICES.TYPE_OF_SERVICES').toString()}
                  </h4>
                  <hr className="traceStart" />
                  <p className="servicesText">
                    {i18next.t('SERVICES.TEXT').toString()}
                  </p>
                  <hr className="traceEnd" />
                  <h5 className="pt-3 mt-5 mb-4 text-bold">{i18next.t('SERVICES.DECORATION').toString()}</h5>
                  <p><FontAwesomeIcon className="small-icon" icon={faCompassDrafting} size="lg" /> {i18next.t('SERVICES.DECORATION_1').toString()}</p>
                  <p><FontAwesomeIcon className="small-icon" icon={faCouch} size="lg" /> {i18next.t('SERVICES.DECORATION_2').toString()}</p>
                  <p><FontAwesomeIcon className="small-icon" icon={faPagelines} size="lg" /> {i18next.t('SERVICES.DECORATION_3').toString()}</p>

                  <h5 className="pt-3 mt-5 mb-4 text-bold">{i18next.t('SERVICES.OTHERS_SERVICES').toString()}</h5>
                  <p><FontAwesomeIcon className="small-icon" icon={faRug} size="lg" /> {i18next.t('SERVICES.OTHERS_SERVICES_1').toString()}</p>
                  <p><FontAwesomeIcon className="small-icon" icon={faBasketShopping} size="lg" /> {i18next.t('SERVICES.OTHERS_SERVICES_2').toString()}</p>
                  <p><FontAwesomeIcon className="small-icon" icon={faDolly} size="lg" /> {i18next.t('SERVICES.OTHERS_SERVICES_3').toString()}</p>
                  <p><FontAwesomeIcon className="small-icon" icon={faCar} size="lg" /> {i18next.t('SERVICES.OTHERS_SERVICES_4').toString()}</p>
              </div>
              <div className="col-md-1 col-lg-2">
              </div>
            </div>
          </div>
        </>
      );
    }
}

export default withTranslation()(Services);