import _ from 'lodash';
import React, { createContext, ReactNode, useContext, useState } from 'react';

interface SimulationContextData {
  showLoader: boolean;
  addLoadingKey(key: string): void;
  removeLoadingKey(key: string): void;
}

interface SimulationProps {
  children: ReactNode;
}

const LoaderContext = createContext<SimulationContextData>({} as SimulationContextData);

export const LoaderProvider = ({ children }: SimulationProps) => {
  const [showLoader, setShowLoader] = useState(false);
  const [loadingKeys, setLoadingKeys] = useState<string[]>([]);

  const addLoadingKey = (key: string) => {
    const _loadingKeys = _.cloneDeep(loadingKeys);
    _loadingKeys.push(key);
    setShowLoader(true);
    setLoadingKeys(_loadingKeys);
  };

  const removeLoadingKey = (key: string) => {
    const _loadingKeys = _.cloneDeep(loadingKeys);
    const indexToRemove = _loadingKeys.findIndex((k) => k === key);
    if (indexToRemove >= 0) {
      _loadingKeys.splice(indexToRemove, 1);
    }
    if (_loadingKeys.length <= 0) {
      setShowLoader(false);
    }
    setLoadingKeys(_loadingKeys);
  };

  return (
    <LoaderContext.Provider value={{ showLoader, addLoadingKey, removeLoadingKey }}>{children}</LoaderContext.Provider>
  );
};

export const useLoader = () => {
  return useContext(LoaderContext);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { useLoader, LoaderProvider };
